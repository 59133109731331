import React from 'react'
import { useParams } from "react-router-dom"
import ReactPlayer from 'react-player/lazy'
import axios from 'axios'
function Videoplayer() {

    const theParam = useParams()

    const videoId = theParam.videoId

    const videoUrl = "https://www.youtube.com/watch?v=0lJKucu6HJc"

    const [show, setShowDetails] = React.useState([])

    const [videoStillLoading, setvideoStillLoading] = React.useState(true)

    // Make a request and get all products
    React.useEffect(() => {
        axios.get(`https://starfish-app-wj9gz.ondigitalocean.app/watch/show/${videoId}`)
            .then(function (response) {

                // handle success
                setShowDetails(response.data);
                setvideoStillLoading(false)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });

    if (videoStillLoading) {
        return (
            <div className='videoLoading text-center mt-40'>
                <h2 className='text-white'>
                    Please wait - video loading
                </h2>
            </div>
        )
    } else {
        return (
            <div>


                {show.map((content) =>
                    <div>
                        <ReactPlayer controls playing="true" width='100%' height="100vh" url={content.contentWatchUrl} />
                    </div>
                )}
            </div>
        )
    }
}

export default Videoplayer