import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Text } from '@nextui-org/react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";

// import Components
import Navbar from '../components/Navbar/index'
import NrtvLogo from '../assets/images/nrtvLogo.png'
import RateCard from '../assets/images/ratecard.jpg'
import Footer from '../components/Footer/footer';

function Advertise() {

    return (
        <div>
            <div className="tvGuideShowsHeader bg-purple-950">
                {/* news navbar */}
                <Navbar />

                {/* tvguide hero section */}
                <section class="relative pt-12 overflow-hidden sm:pt-16">
                    <div class="relative px-2 mx-auto sm:px-6 lg:px-4 max-w-7xl">
                        <div class="max-w-4xl mx-auto text-center">
                            <p class=" font-normal tracking-widest uppercase">
                                <span class="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500 text-2xl">
                                    ADVERTISE ON OUR PLATFORMS
                                </span>
                            </p>
                        </div>

                        <div class="relative mt-12 -mb-4 sm:-mb-10 lg:-mb-12 sm:mt-16 lg:mt-24">
                            <div class="absolute top-0 transform -translate-x-1/2 left-1/2">
                                <svg class="blur-3xl filter" width="645" height="413" viewBox="0 0 645 413" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M181.316 218.778C86.2529 123.715 -63.7045 134.94 31.3589 39.8762C126.422 -55.1873 528.427 41.1918 623.49 136.255C718.554 231.319 470.678 289.068 375.614 384.131C280.551 479.195 276.38 313.842 181.316 218.778Z" fill="url(#d)" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </section>

                {/* the rate card */}
                <img src={RateCard} className='mt-20 mx-auto sm:px-6 lg:px-8 max-w-7xl' style={{width: "85vh"}}/>
                 
                <footer className='mt-20'>
                    <div>
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-y-12 xl:gap-x-16 gap-x-8">
                            <div className="col-span-2 md:col-span-4 lg:col-span-2">
                                <a href="#" title="" className="flex">
                                    <img className="w-auto h-9" src={NrtvLogo} alt="" />
                                </a>
                                <p className="mt-6 text-base font-normal text-white">NRTV Zimbabwe. World class content beyond your imagination.</p>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">Platform</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1">Livestream </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> News </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1">Shop </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Request Content </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">Resources</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> About </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Features </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Pricing & Plans </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Contact </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">More</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> About Us </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Contact Us </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Contribute </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1">Support </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">More</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Guides </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Terms & Conditions </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Privacy Policy </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Live Stream </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <hr className="mt-12 border-gray-400 lg:mt-20 xl:mt-24 sm:mt-16" />

                        <p className="mt-8 text-sm font-normal text-center text-white">© 2023, All Rights Reserved by NRTV</p>
                    </div>
                </footer>

            </div>
        </div>
    )
}

export default Advertise