import React from 'react'
import { Link } from 'react-router-dom'

// import components
import Navbar from './../components/Navbar/index'
import WatchShows from './../components/WatchShows/index'
import NewsByCategory from '../components/NewsByCategory'
import Footer from '../components/Footer/footer'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";

// import images
import NewBarner from '../assets/images/2024Barner.png'

// watch shows
import NewWatchShows from '../components/WatchShows/watchShows'

function Index() {
  const [showModal, setShowModal] = React.useState(true)

  const closeHandler = () => {
    setShowModal(true);
  };

  return (
    <div>
      {/* ad modal */}
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={showModal}
        onClose={closeHandler}
        width="100vh"
        height="90vh"
      >
        <Modal.Body>
          <div className="videoDiv">
            <h2 className='text-center font-light mb-4'>
              sponsored ads
            </h2>

            <div class="grid grid-cols-3 gap-4">
              <div class="...">
                <img className='h-80 w-98' src="https://www.zimbojam.com/wp-content/uploads/2023/06/355611974_220807350833833_1476684623506306456_n.jpg" alt="" />
              </div>
              <div class="...">
                <img className='h-80 w-98' src="https://pbs.twimg.com/media/FzDEjlUXoAEY8Ja?format=jpg&name=medium" alt="" />
              </div>

              <div class="...">
                <img className='h-80 w-98' src="https://pbs.twimg.com/media/FzSOMkVWcAEufhS?format=jpg&name=medium" alt="" />
              </div>

            </div>
            <h2 className='text-center font-light mb-0'>
              to advertise here
            </h2>
            <h2 className='text-center font-light mb-4'>
              email us on marketing@nrtv.tv
            </h2>
          </div>

          {/* btns */}


        </Modal.Body>
      </Modal>

      <div className='headerHeroArea bg-purple-950'>

        {/* navbar header area */}
        <Navbar />

        {/* the hero area */}
        <section className="pt-12 sm:pt-8">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-2xl mx-auto text-center">
              <h1 className="px-6 text-lg text-gray-400 font-inter">Beyond your Imagination!</h1>
              <p className="mt-4 text-xl font-bold leading-tight text-gray-400 sm:leading-tight sm:text-5xl lg:text-4xl lg:leading-tight font-pj">
                <span class="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">Your world class TV station</span>


                <span className="ml-2 relative inline-flex sm:inline">
                  <span className="relative text-gray-200"> now here </span>
                </span>
              </p>

            </div>

            <div>
              <img className='mt-20 mb-10 ' src={NewBarner}></img>
            </div>

          </div>
        </section>

        <div className='px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl'>


          {/* watch shows categories */}
          <div className='my-10'>

            {/* watch shows */}
            <div className='text-gray-100 text-lg'>
              <NewWatchShows />
              {/* <WatchShows /> */}
            </div>

            {/* News by category */}
            <NewsByCategory />

            {/* footer */}
            <Footer />

          </div>

        </div>
      </div >

      {/* ads area */}
      <div className='adsCards mt-8'>
        <div class="grid grid-cols-6 gap-4">
         
          {/* <div class="col-start-1 col-end-3 ...">
            <div className='rightFirstAd ml-8 text-gray-400'>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
            </div>
          </div> */}

          {/* <div class="col-end-7 col-span-2 ...">
            <div className='leftFirstAd text-gray-400'>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
              <h5>ad here</h5>
            </div>
          </div> */}

        </div>
      </div>

    </div >
  )
}

export default Index