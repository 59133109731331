import React from 'react'

// import Components
import Navbar from '../components/Navbar/index'
import axios from 'axios'
import { useParams } from "react-router-dom"
import { useLocation } from "react-router-dom";

import parse from 'html-react-parser';

import NrtvLogo from '../assets/images/nrtvLogo.png'

function Newsdetails() {
    const theParam = useParams()

    const articleId = theParam.articleId

    const [article, setArticle] = React.useState([])

    // Make a request and get news article
    React.useEffect(() => {
        axios.get(`https://starfish-app-wj9gz.ondigitalocean.app/news/article/${articleId}`)
            .then(function (response) {

                // handle success
                setArticle(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });
    console.log(article)

    const { pathname } = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div>
            <div className='bg-purple-950'>
                <Navbar />

                {/* news details area */}
                <section className="py-8 sm:py-16 lg:py-8">
                    <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    {article.map((article) =>
                        <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-6 lg:gap-x-8 xl:gap-x-10">
                            <div className="bg-gray-100 lg:col-span-4 rounded-xl">
                                <div className="p-1">
                                    <img src={article.newsImageUrl} className='newsImage' alt="" srcset="" />
                                </div>
                            </div>

                            {/*  news detailes side bar */}
                        
                                <div className="lg:col-span-2">
                                    <div className="socialMediaFollow">
                                        {/* social media follow icons */}
                                        <p class="text-lg text-gray-300">Follow and like us on:</p>
                                        <ul class="flex items-center space-x-3 my-6">
                                            <li>
                                                <a href="#" title="" class="inline-flex items-center justify-center w-10 h-10 text-white transition-all duration-200 bg-gray-900 rounded-md hover:bg-gray-700 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-gray-900" role="button">
                                                    <span class="sr-only"> Discord </span>
                                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                        <path
                                                            d="M14.82 4.26a10.14 10.14 0 0 0-.53 1.1 14.66 14.66 0 0 0-4.58 0 10.14 10.14 0 0 0-.53-1.1 16 16 0 0 0-4.13 1.3 17.33 17.33 0 0 0-3 11.59 16.6 16.6 0 0 0 5.07 2.59A12.89 12.89 0 0 0 8.23 18a9.65 9.65 0 0 1-1.71-.83 3.39 3.39 0 0 0 .42-.33 11.66 11.66 0 0 0 10.12 0q.21.18.42.33a10.84 10.84 0 0 1-1.71.84 12.41 12.41 0 0 0 1.08 1.78 16.44 16.44 0 0 0 5.06-2.59 17.22 17.22 0 0 0-3-11.59 16.09 16.09 0 0 0-4.09-1.35zM8.68 14.81a1.94 1.94 0 0 1-1.8-2 1.93 1.93 0 0 1 1.8-2 1.93 1.93 0 0 1 1.8 2 1.93 1.93 0 0 1-1.8 2zm6.64 0a1.94 1.94 0 0 1-1.8-2 1.93 1.93 0 0 1 1.8-2 1.92 1.92 0 0 1 1.8 2 1.92 1.92 0 0 1-1.8 2z"
                                                        ></path>
                                                    </svg>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#" title="" class="inline-flex items-center justify-center w-10 h-10 text-white transition-all duration-200 bg-gray-900 rounded-md hover:bg-gray-700 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-gray-900" role="button">
                                                    <span class="sr-only"> Twitter </span>
                                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                        <path
                                                            d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"
                                                        ></path>
                                                    </svg>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#" title="" class="inline-flex items-center justify-center w-10 h-10 text-white transition-all duration-200 bg-gray-900 rounded-md hover:bg-gray-700 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-gray-900" role="button">
                                                    <span class="sr-only"> Telegram </span>
                                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                        <path
                                                            d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"
                                                        ></path>
                                                    </svg>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#" title="" class="inline-flex items-center justify-center w-10 h-10 text-white transition-all duration-200 bg-gray-900 rounded-md hover:bg-gray-700 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-gray-900" role="button">
                                                    <span class="sr-only"> YouTube </span>
                                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                        <path
                                                            d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z"
                                                        ></path>
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div>
                                        <p className="text-lg text-gray-300">News Tags</p>

                                        <div className="flex flex-wrap mt-5 gap-2.5">
                                            <a href="#" className="inline-block">
                                                <span className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-900 transition-all duration-200 bg-white border border-gray-200 rounded-md hover:bg-gray-50">
                                                    Latest
                                                </span>
                                            </a>

                                            <a href="#" className="inline-block">
                                                <span className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-900 transition-all duration-200 bg-white border border-gray-200 rounded-md hover:bg-gray-50">
                                                    Current Affairs
                                                </span>
                                            </a>

                                            <a href="#" className="inline-block">
                                                <span className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-900 transition-all duration-200 bg-white border border-gray-200 rounded-md hover:bg-gray-50">
                                                    Crime and Courts
                                                </span>
                                            </a>

                                            <a href="#" className="inline-block">
                                                <span className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-900 transition-all duration-200 bg-white border border-gray-200 rounded-md hover:bg-gray-50">
                                                    Sports
                                                </span>
                                            </a>

                                            <a href="#" className="inline-block">
                                                <span className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-900 transition-all duration-200 bg-white border border-gray-200 rounded-md hover:bg-gray-50">
                                                    Business and Finance
                                                </span>
                                            </a>

                                            <a href="#" className="inline-block">
                                                <span className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-900 transition-all duration-200 bg-white border border-gray-200 rounded-md hover:bg-gray-50">
                                                    Regional
                                                </span>
                                            </a>

                                            <a href="#" className="inline-block">
                                                <span className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-900 transition-all duration-200 bg-white border border-gray-200 rounded-md hover:bg-gray-50">
                                                    International
                                                </span>
                                            </a>

                                            <a href="#" className="inline-block">
                                                <span className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-900 transition-all duration-200 bg-white border border-gray-200 rounded-md hover:bg-gray-50">
                                                    Agriculture
                                                </span>
                                            </a>

                                            <a href="#" className="inline-block">
                                                <span className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-900 transition-all duration-200 bg-white border border-gray-200 rounded-md hover:bg-gray-50">
                                                    Politics
                                                </span>
                                            </a>

                                            <a href="#" className="inline-block">
                                                <span className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-900 transition-all duration-200 bg-white border border-gray-200 rounded-md hover:bg-gray-50">
                                                    Entertainment
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="rounded-sm border border-gray-500 mt-4">
                                        <p className='text-center py-8'>
                                            sponsored ad
                                        </p>
                                    </div>

                                    {/* recent side bar posts */}
                                </div>
                          
                        </div>
                        )}
                    </div>
                </section>
            </div>

            <div className="mx-auto sm:px-6 lg:px-8 max-w-7xl mt-4">
                {
                    article.map((article) =>
                        <div class="grid grid-cols-3 gap-4 mb-40">
                            <div class="col-span-2 ...">
                                <div>
                                    <div class="max-w-2xl">
                                        <h1 class="text-4xl font-bold text-white sm:text-5xl">{article.newsTitle}</h1>
                                        <p class="mt-6 text-base font-bold font-medium text-white">by {article.writtenBy}</p>
                                        <p class="text-base font-bold font-medium text-white mb-10">{article.datePublished}</p>
                                    </div>

                                    <p class="text-md text-white">
                                        {parse(`${article.newsBody}`)}
                                    </p>
                                </div>
                            </div>
                            <div class="..."></div>
                        </div>
                    )
                }

            </div>

            <div className='mx-auto sm:px-6 lg:px-8 max-w-7xl pt-20'>
                <footer>
                    <div>
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-y-12 xl:gap-x-16 gap-x-8">
                            <div className="col-span-2 md:col-span-4 lg:col-span-2">
                                <a href="#" title="" className="flex">
                                    <img className="w-auto h-9" src={NrtvLogo} alt="" />
                                </a>
                                <p className="mt-6 text-base font-normal text-white">NRTV Zimbabwe. World class content beyond your imagination.</p>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">Platform</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1">Livestream </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> News </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1">Shop </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Request Content </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">Resources</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> About </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Features </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Pricing & Plans </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Contact </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">More</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> About Us </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Contact Us </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Contribute </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1">Support </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">More</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Guides </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Terms & Conditions </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Privacy Policy </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Live Stream </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <hr className="mt-12 border-gray-400 lg:mt-20 xl:mt-24 sm:mt-16" />

                        <p className="mt-8 text-sm font-normal text-center text-white">© 2023, All Rights Reserved by NRTV</p>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Newsdetails