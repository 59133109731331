import React from 'react'
import { Link, useLocation  } from 'react-router-dom'

function Navbar ( props ) {
    const location = useLocation()
    const { window, children } = props;

    const setBorderAndColor = (path) => {
        if (location.pathname === path) {
            return " custom-link"
        // } else if (anotherPath === path) {
        //     return "border-r-4 border-pink-300 "
        }
    }

    return (
        <div>
            <header className="py-4 sm:py-5">
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl mt-1">
                    <nav className="flex items-center justify-between">
                        <div className="flex shrink-0">
                            <Link to="/">
                                <a title="" className="flex">
                                    <img className="w-auto h-10" src="https://nrtv.tv/static/media/nrtvLogo.9b2c9af833c11c09beb6.png" alt=""></img>
                                </a>
                            </Link>
                        </div>

                        <div className="flex lg:hidden">
                            <button type="button" className="text-gray-900">
                                <span x-show="!expanded" aria-hidden="true">
                                    <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 6h16M4 12h16M4 18h16" />
                                    </svg>
                                </span>

                                <span x-show="expanded" aria-hidden="true">
                                    <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="hidden lg:items-center lg:justify-start lg:ml-16 lg:mr-auto lg:space-x-10 lg:flex">
                            <Link to="/watch">
                                <a title=""
                                    className={`text-base font-small text-gray-400 transition-all duration-200 hover:text-gray-300 ${setBorderAndColor('/watch')}`}>
                                    Watch Shows
                                </a>
                            </Link>

                            <Link to="/news">
                                <a title=""
                                     className={`text-base font-small text-gray-400 transition-all duration-200 hover:text-gray-300 ${setBorderAndColor('/news')}`}>
                                    News
                                </a>
                            </Link>

                            <Link to="/tvguide">
                                <a title=""
                                  className={`text-base font-small text-gray-400 transition-all duration-200 hover:text-gray-300 ${setBorderAndColor('/tvguide')}`}>
                                    TV Guide
                                </a>
                            </Link>

                            <Link to="/advertise">
                                <a title=""
                                    className={`text-base font-small text-gray-400 transition-all duration-200 hover:text-gray-300 ${setBorderAndColor('/advertise')}`}>
                                    Advertise with Us
                                </a>
                            </Link>
                        </div>

                        <div className="hidden lg:flex lg:items-center lg:justify-end lg:space-x-4">
                        <nav className="hidden lg:flex lg:items-center lg:justify-end">
                        <ul className="flex items-center justify-center mt-8 space-x-3 sm:mt-12 lg:justify-end lg:mt-0">
                            <li>
                                <a href="https://twitter.com/nrtv_zw" target="_blank" title="" className="inline-flex items-center justify-center w-10 h-10 text-white hover:bg-black hover:border transition-all duration-200 rounded-full 
                                            focus:outline-none" rel="noopener">
                                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path
                                            d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"
                                        ></path>
                                    </svg>
                                </a>
                            </li>

                            <li>
                                <a href="https://www.facebook.com/profile.php?id=100087137086836" target="_blank" title="" className="inline-flex items-center justify-center w-10 h-10 text-white 
                                        transition-all duration-200 rounded-full focus:outline-none hover:bg-black hover:border" rel="noopener">
                                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                                    </svg>
                                </a>
                            </li>

                            <li>
                                <a href="https://www.instagram.com/nrtvzimbabwe/" target="_blank" title="" className="inline-flex items-center justify-center w-10 h-10 text-white hover:bg-black hover:border transition-all duration-200 
                                        rounded-full  focus:outline-none" rel="noopener">
                                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                                        <circle cx="16.806" cy="7.207" r="1.078"></circle>
                                        <path
                                            d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"
                                        ></path>
                                    </svg>
                                </a>
                            </li>

                            <li>
                                <a href="https://www.linkedin.com/in/nrtv-zim-37782a256/" title="" target="_blank" rel="noopener" className="inline-flex items-center justify-center w-10 h-10 text-white transition-all 
                                        duration-200 rounded-full hover:bg-black hover:border focus:outline-none">
                                    <span class="sr-only"> LinkedIn </span>
                                    <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <circle cx="4.983" cy="5.009" r="2.188"></circle>
                                        <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path>
                                    </svg>
                                </a>
                            </li>


                            {/* <Switch
                                checked={true}
                                size="xl"
                                iconOn={
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
                                    </svg>

                                }

                                iconOff={
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
                                    </svg>
                                }
                            /> */}
                        </ul>
                    </nav>

                            <Link to="/contactus">
                                <a title="" class="inline-flex items-center justify-center px-6 py-2 sm:py-2.5 text-base font-semibold text-white 
                                transition-all duration-200 bg-green-500 rounded-md sm:text-sm hover:bg-gray-700 focus:outline-none 
                                focus:ring-2 focus:ring-offset-2 focus:ring-gray-900" role="button">Contact Us</a>
                            </Link>

                        </div>
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Navbar