import React from 'react'

// import Components
import Navbar from '../components/Navbar/index'

import parse from 'html-react-parser';

// axios
import axios from 'axios'

import { Link } from 'react-router-dom';

function News() {
    // all news tabs
    const tabItems = ["Latest News", "Current Affairs", "Crime and Courts", "Sports", "Business and Finance", "Regional", "International", "Agriculture", "Politics", "Entertainment"]
    const [selectedItem, setSelectedItem] = React.useState(0)

    // set response data
    const [news, setNews] = React.useState([])

    // Make a request and get all news
    React.useEffect(() => {
        axios.get('https://starfish-app-wj9gz.ondigitalocean.app/news/')
            .then(function (response) {

                // handle success
                setNews(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });


    return (
        <div>
            <div className=" bg-purple-950">
                {/* news navbar */}
                <Navbar />

                {/* news header */}
                <section class="relative pt-12 overflow-hidden sm:pt-16">
                    <div class="relative px-2 mx-auto sm:px-6 lg:px-4 max-w-7xl">
                        <div class="max-w-4xl mx-auto text-center">
                            <p class="text-sm font-normal tracking-widest uppercase">
                                <span class="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> Your accurate news plug </span>
                            </p>
                        </div>

                        <div class="relative mt-12 -mb-4 sm:-mb-10 lg:-mb-12 sm:mt-16 lg:mt-24">
                            <div class="absolute top-0 transform -translate-x-1/2 left-1/2">
                                <svg class="blur-3xl filter" width="645" height="413" viewBox="0 0 645 413" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M181.316 218.778C86.2529 123.715 -63.7045 134.94 31.3589 39.8762C126.422 -55.1873 528.427 41.1918 623.49 136.255C718.554 231.319 470.678 289.068 375.614 384.131C280.551 479.195 276.38 313.842 181.316 218.778Z" fill="url(#d)" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="newsArea">

                {/* news articles area */}
                {/* news tab */}
                <div className='mt-2 px-4 mx-auto sm:px-6 lg:px-6 max-w-7xl'>
                    <ul role="tablist" className="hidden max-w-screen-xl px-2.5 items-center gap-x-3 overflow-x-auto text-sm rounded-md sm:flex border border-pink-100 bg-purple-950">
                        {
                            tabItems.map((item, idx) => (
                                <li key={idx} className="py-3">
                                    <button
                                        role="tab"
                                        aria-selected={selectedItem == idx ? true : false}
                                        aria-controls={`tabpanel-${idx + 1}`}
                                        className={`text-black tabsResultsT ext py-2.5 px-4 rounded-lg duration-150 hover:text-gray-500 hover:bg-white active:bg-white/50 font-medium ${selectedItem == idx ? "bg-white text-gray-500 shadow-sm" : "text-gray-500"}`}
                                        onClick={() => setSelectedItem(idx)}
                                    >
                                        {item}
                                    </button>
                                </li>
                            ))
                        }
                    </ul>

                    <div className="relative text-gray-500 sm:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="pointer-events-none w-5 h-5 absolute right-2 inset-y-0 my-auto">
                            <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                        </svg>
                        <select value={tabItems[selectedItem]} className="p-3 w-full bg-transparent appearance-none outline-none border rounded-md shadow-sm focus:border-indigo-600"
                            onChange={(e) => setSelectedItem(tabItems.indexOf(e.target.value))}
                        >
                            {
                                tabItems.map((item, idx) => (
                                    <option key={idx} idx={idx}>
                                        {item}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                {/* articles */}
                <section className='mb-20'>
                    <div className="px-4 mx-auto sm:px-6 lg:px-6 max-w-7xl">
                        <div className="grid grid-cols-1 gap-6 px-6 mt-8 sm:mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:px-0">

                            {
                                news.map((news) => (
                                  <Link to={`/news/${news._id}`} className="flex flex-col overflow-hidden transition-all duration-200 transform bg-white border border-gray-100 shadow group rounded-md hover:shadow-lg hover:-translate-y-1">
                                    <div >
                                        <a title="" className="flex shrink-0 aspect-w-4 aspect-h-3">
                                            <img className="newsCardImage object-cover w-full transition-all duration-200 transform group-hover:scale-110" src={news.newsImageUrl} alt="" />
                                        </a>
                                        <div className="flex-1 px-4 py-5 sm:p-6">
                                            <a title="" className="">
                                                <p className="text-lg font-bold text-gray-900">
                                                    {news.newsTitle}
                                                </p>
                                                <p className="mt-3 text-sm font-normal leading-6 text-gray-500 line-clamp-3">
                                                    {parse(`${news.newsBody}`)}
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                  </Link>
                                ))
                            }

                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default News