import React from 'react'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// all pages
import Index from './pages/index'
import News from './pages/news'
import Newsdetails from './pages/newsdetails'
import WatchShows from './pages/watchshows'
import TvGuide from './pages/tvguide'
import About from './pages/about'
import ContactUs from './pages/contactus'
import Videoplayer from './pages/watchShow'
import Advertise from './pages/advertise'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* auth routes */}
          <Route path="/" element={<Index />} />
          <Route path="/advertise" element={<Advertise />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:articleId" element={<Newsdetails />} />
          <Route path="/watch" element={<WatchShows />} />
          <Route path="/watch/:videoId" element={<Videoplayer />} />

          <Route path="/tvguide" element={<TvGuide />} />
          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<ContactUs />} />

          {/* not found page */}
          {/* <Route path='*' element={< NotFoundPage />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;