import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// import Components
import Navbar from '../components/Navbar/index'

import axios from 'axios'

// import logos for shows
import Logo from '../assets/images/dailyDoseLogo.png'
import Logo2 from '../assets/images/theMob.png'

import NrtvLogo from '../assets/images/nrtvLogo.png'

function WatchShows() {
    // watch shows tabs
    const tabItems = ["Live Shows", "News and Current Affairs", "Movies and Drama", "Kids Shows", "Documentaries", "Reality Shows", "Sports"]
    const [selectedItem, setSelectedItem] = useState(0)

    // shows
    const [shows, setShows] = React.useState([])

    // Make a request and get all shows 
    React.useEffect(() => {
        axios.get('https://starfish-app-wj9gz.ondigitalocean.app/watch/')
            .then(function (response) {

                // handle success
                setShows(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    });

    return (
        <div>
            <div className="watchShowsHeader bg-purple-950">
                {/* news navbar */}
                <Navbar />

                <div className="watchShowsArea">
                    <section class="py-16 sm:pb-16 lg:pb-20 xl:pb-24">
                        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                            <div class="relative">
                                <div class="lg:w-2/3">
                                    <p class="text-sm font-normal tracking-widest text-gray-300 uppercase">FEATURED SHOW</p>
                                    {/* <img class="w-14 h-14" src={Logo2} alt="" /> */}
                                    <h1 class="mt-6 text-4xl font-normal text-white sm:mt-10 sm:text-5xl lg:text-6xl xl:text-8xl"><span class="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">The </span> Mob</h1>
                                    <p class="max-w-lg mt-4 text-xl font-normal text-gray-400 sm:mt-8">The Mobse is a youth lifestyle show on our Channel. It focuses on topics like entertainment, food, religion and much more. Catch it live every Saturday and Sunday mornings.</p>
                                    <div class="relative inline-flex items-center justify-center mt-8 sm:mt-12 group">
                                        <div class="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>

                                        <Link to="/watch">
                                            <a title="" class="relative inline-flex items-center justify-center px-8 py-3 text-base font-normal text-white bg-black border border-transparent rounded-full" role="button"> Watch The Show Trailer</a>
                                        </Link>
                                    </div>
                                </div>

                                <div class="mt-8 md:absolute md:mt-0 md:top-32 lg:top-0 md:right-0">
                                    <img class="w-full max-w-xs mx-auto lg:max-w-lg xl:max-w-xl" src="https://landingfoliocom.imgix.net/store/collection/dusk/images/hero/1/3d-illustration.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* all shows area */}
                    {/* shows tab */}

                    <div className='mt-8 px-4 mx-auto sm:px-6 lg:px-6 max-w-7xl'>
                        <ul role="tablist" className="hidden max-w-screen-xl px-2.5 items-center gap-x-3 overflow-x-auto text-sm rounded-md sm:flex border border-pink-100 bg-purple-950">
                            {
                                tabItems.map((item, idx) => (
                                    <li key={idx} className="py-3">
                                        <button
                                            role="tab"
                                            aria-selected={selectedItem == idx ? true : false}
                                            aria-controls={`tabpanel-${idx + 1}`}
                                            className={`text-black tabsResultsT ext py-2.5 px-4 rounded-lg duration-150 hover:text-gray-500 hover:bg-white active:bg-white/50 font-medium ${selectedItem == idx ? "bg-white text-gray-500 shadow-sm" : "text-gray-500"}`}
                                            onClick={() => setSelectedItem(idx)}
                                        >
                                            {item}
                                        </button>
                                    </li>
                                ))
                            }
                        </ul>

                        <div className="relative text-gray-500 sm:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="pointer-events-none w-5 h-5 absolute right-2 inset-y-0 my-auto">
                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                            </svg>
                            <select value={tabItems[selectedItem]} className="p-3 w-full bg-transparent appearance-none outline-none border rounded-md shadow-sm focus:border-indigo-600"
                                onChange={(e) => setSelectedItem(tabItems.indexOf(e.target.value))}
                            >
                                {
                                    tabItems.map((item, idx) => (
                                        <option key={idx} idx={idx}>
                                            {item}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>

                        {/* filtered shows */}
                        <div class="grid max-w-md grid-cols-1 gap-5 mx-auto mt-8 xl:gap-6 lg:grid-cols-3 lg:max-w-none sm:mt-10 mb-20">
                            {shows.map((shows) =>
                                <Link to={`/watch/${shows._id}`}>
                                    <div class="p-6 watchCard transition-all duration-200 bg-purple-950 group rounded-2xl hover:-translate-y-1">
                                        <a title="" class="block overflow-hidden aspect-w-16 aspect-h-9 rounded-xl">
                                            <img class="object-cover w-full h-full transition-all duration-200 transform group-hover:scale-110 videosImage" src={shows.contentCoverImageUrl} alt="" />
                                        </a>

                                        <div class="flex items-center justify-between mt-6">
                                            <p class="text-sm font-medium text-gray-400">
                                                <a title="" class=""> {shows.contentTitle} </a>
                                            </p>

                                            <p class="text-sm font-medium text-gray-400">26 June 2022</p>
                                        </div>

                                        <hr class="my-5 border-gray-700" />

                                        <h3 class="text-sm font-light text-white xl:text-xl group-hover:text-gray-200">
                                            <a title="" class=""> {shows.contentDescription} </a>
                                        </h3>
                                    </div>
                                </Link>
                            )}
                        </div>

                        <footer className='mt-20'>
                    <div>
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-y-12 xl:gap-x-16 gap-x-8">
                            <div className="col-span-2 md:col-span-4 lg:col-span-2">
                                <a href="#" title="" className="flex">
                                    <img className="w-auto h-9" src={NrtvLogo} alt="" />
                                </a>
                                <p className="mt-6 text-base font-normal text-white">NRTV Zimbabwe. World class content beyond your imagination.</p>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">Platform</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1">Livestream </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> News </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1">Shop </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Request Content </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">Resources</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> About </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Features </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Pricing & Plans </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Contact </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">More</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> About Us </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Contact Us </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Contribute </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1">Support </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">More</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Guides </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Terms & Conditions </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Privacy Policy </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Live Stream </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <hr className="mt-12 border-gray-400 lg:mt-20 xl:mt-24 sm:mt-16" />

                        <p className="mt-8 text-sm font-normal text-center text-white">© 2023, All Rights Reserved by NRTV</p>
                    </div>
                </footer>

                    </div>

                </div>
            </div>


        </div>
    )
}

export default WatchShows