import React from 'react'
import { Text } from '@nextui-org/react'
import { Link } from 'react-router-dom'

function watchShows() {
    return (
        <div>
            <div className="my-8">
                <Link to="/watch">
                    <div className="flex items-center justify-center text-center md:justify-between sm:text-left">
                        <h1 className="text-2xl text-gray-300 font-inter">Watch Our Shows Online | Unlimited Entertaining Choices </h1>
                    </div>
                </Link>
            </div>

            <div class="grid grid-cols-4 gap-4">
                <Link to="/watch">
                    <div className="h-18 py-2 rounded-md bg-purple-500 shadow-lg hover:shadow-lg border border-yellow-500">
                        <div class="grid grid-cols-3 gap-0 p-4">
                            <div class="mt-1 ml-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-black">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                </svg>
                            </div>

                            <div class="col-span-2">
                                <Text className='font-base text-sm'>
                                    Live Shows
                                </Text>
                                <Text className='text-sm font-light'>
                                    stream shows live
                                </Text>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/watch">
                    <div className="h-18 py-2 rounded-md bg-purple-500 shadow-lg hover:shadow-lg border border-yellow-500">
                        <div class="grid grid-cols-3 gap-0 p-4">
                            <div class="mt-1 ml-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-black">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                                </svg>
                            </div>

                            <div class="col-span-2">
                                <Text className='font-base text-sm'>
                                    News and Current Affairs
                                </Text>
                                <Text className='text-sm font-light'>
                                    current up to date news
                                </Text>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/watch"> 
                    <div className="h-18 py-2 rounded-md shadow-lg hover:shadow-lg bg-purple-500 border border-yellow-500">
                        <div class="grid grid-cols-3 gap-0 p-4">
                            <div class="mt-1 ml-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-black">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0118 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0118 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 016 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5" />
                                </svg>

                            </div>

                            <div class="col-span-2">
                                <Text className='font-base text-sm'>
                                    Movies and Drama
                                </Text>
                                <Text className='text-sm font-light'>
                                    world class movies
                                </Text>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/watch">
                    <div className="h-18 py-2 rounded-md bg-purple-500 shadow-lg hover:shadow-lg border border-yellow-500">
                        <div class="grid grid-cols-3 gap-0 p-4">
                            <div class="mt-1 ml-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-black">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
                                </svg>

                            </div>

                            <div class="col-span-2">
                                <Text className='font-base text-sm'>
                                    Documentaries
                                </Text>
                                <Text className='text-sm font-light'>
                                    real life Documentaries
                                </Text>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/watch">
                    <div className="h-18 py-2 rounded-md bg-purple-500 shadow-lg hover:shadow-lg border border-yellow-500 mt-4">
                        <div class="grid grid-cols-3 gap-0 p-4">
                            <div class="mt-1 ml-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-black">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288" />
                                </svg>
                            </div>

                            <div class="col-span-2">
                                <Text className='font-base text-sm'>
                                    Kids Shows
                                </Text>
                                <Text className='text-sm font-light'>
                                    kids related shows
                                </Text>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/watch">
                    <div className="h-18 py-2 rounded-md bg-purple-500 shadow-lg hover:shadow-lg border border-yellow-500 mt-4">
                        <div class="grid grid-cols-3 gap-0 p-4">
                            <div class="mt-1 ml-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-black">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" />
                                </svg>

                            </div>

                            <div class="col-span-2">
                                <Text className='font-base text-sm'>
                                    Sports Shows
                                </Text>
                                <Text className='text-sm font-light'>
                                    all sports
                                </Text>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/watch">
                    <div className="h-18 py-2 rounded-md bg-purple-500 shadow-lg hover:shadow-lg border border-yellow-500 mt-4">
                        <div class="grid grid-cols-3 gap-0 p-4">
                            <div class="mt-1 ml-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 text-black h-10">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125z" />
                                </svg>
                            </div>

                            <div class="col-span-2">
                                <Text className='font-base text-sm'>
                                    Reality Shows
                                </Text>
                                <Text className='text-sm font-light'>
                                    real life shows
                                </Text>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/watch">
                    <div className="h-18 py-2 rounded-md bg-purple-500 shadow-lg hover:shadow-lg border border-yellow-500 mt-4">
                        <div class="grid grid-cols-3 gap-0 p-4">
                            <div class="mt-1 ml-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-black">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 9l10.5-3m0 6.553v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 11-.99-3.467l2.31-.66a2.25 2.25 0 001.632-2.163zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 01-.99-3.467l2.31-.66A2.25 2.25 0 009 15.553z" />
                                </svg>

                            </div>

                            <div class="col-span-2">
                                <Text className='font-base text-sm'>
                                    Entertainment Shows
                                </Text>
                                <Text className='text-sm font-light'>
                                    captivating entertainment
                                </Text>
                            </div>
                        </div>
                    </div>
                </Link>

            </div>
        </div>
    )
}

export default watchShows