import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Text } from '@nextui-org/react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";

// import Components
import Navbar from '../components/Navbar/index'
import NrtvLogo from '../assets/images/nrtvLogo.png'

function TvGuide() {
    const tabItems = ["05:45 to 06:00", "06:00 to 08:00", "08:00 to 08:15", "08:15 to 08:30", "08:30 to 08:45", "08:45 to 09:00", "09:00 to 09:15", "09:15 to 09:30", "09:30 to 10:00", "10:00 to 10:05", "10:05 to 10:30",
        "10:30 to 11:00", "11:00 to 11:30", "11:30 to 12:00", "12:00 to 12:30", "12:30 to 12:45", "12:45 to 13:00", "13:00 to 13:30", "13:30 to 13:45", "13:45 to 14:00", "14:00 to 14:30", "14:30 to 15:00",
        "14:30 to 15:00", "15:00 to 15:30", "15:30 to 16:00"]

    const [selectedItem, setSelectedItem] = useState(0)

    const [clickedvalue, setClickedValue] = React.useState(0)

    const [showModal, setShowModal] = React.useState(true)

    const closeHandler = () => {
      setShowModal(true);
    };

    const renderTabContent = () => {
        if (selectedItem === 0) {
            return <div>
                <h5>0</h5>
            </div>;

        } else if (selectedItem === 1) {
            return <div>
                <h5>1</h5>
            </div>;

        } else if (selectedItem === 2) {
            return <div>
                <h5>2</h5>
            </div>;

        } else if (selectedItem === 3) {
            return <div>
                <h5>3</h5>
            </div>;

        } else {
            return null; // No content for other tabs
        }
    };

    return (
        <div>
                        <div className="tvGuideShowsHeader bg-purple-950">
                {/* news navbar */}
                <Navbar />

                {/* tvguide hero section */}
                <section class="relative pt-12 overflow-hidden sm:pt-16">
                    <div class="relative px-2 mx-auto sm:px-6 lg:px-4 max-w-7xl">
                        <div class="max-w-4xl mx-auto text-center">
                            <p class=" font-normal tracking-widest uppercase">
                                <span class="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500 text-2xl">Our Season 2 Tv Guide</span>
                            </p>
                        </div>

                        <div class="relative mt-12 -mb-4 sm:-mb-10 lg:-mb-12 sm:mt-16 lg:mt-24">
                            <div class="absolute top-0 transform -translate-x-1/2 left-1/2">
                                <svg class="blur-3xl filter" width="645" height="413" viewBox="0 0 645 413" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M181.316 218.778C86.2529 123.715 -63.7045 134.94 31.3589 39.8762C126.422 -55.1873 528.427 41.1918 623.49 136.255C718.554 231.319 470.678 289.068 375.614 384.131C280.551 479.195 276.38 313.842 181.316 218.778Z" fill="url(#d)" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </section>

                {/* the tabs */}
                <div className=' mx-auto sm:px-6 lg:px-12 pt-20 max-w-7xl'>
                    <div className="flex">
                        <div className="inline-block mr-16 mb-40">
                            <div>
                                <ul role="tablist" className="mt-16 hidden max-w-screen-xl border-l flex-col gap-y-3 text-sm sm:flex">
                                    {
                                        tabItems.map((item, idx) => (
                                            <li key={idx} className={`${selectedItem === idx ? "text-black bg-purple-400 border border-gray-400 rounded-r-lg" : " text-gray-200"}`}>
                                                <button
                                                    role="tab"
                                                    aria-selected={selectedItem === idx ? true : false}
                                                    aria-controls={`tabpanel-${idx + 1}`}
                                                    className="py-2.5 px-4 rounded-lg duration-150 font-medium"
                                                    onClick={() => setSelectedItem(idx)}
                                                >
                                                    {item}
                                                </button>
                                            </li>
                                        ))
                                    }
                                </ul>

                                <div className="relative text-gray-500 sm:hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="pointer-events-none w-5 h-5 absolute right-2 inset-y-0 my-auto">
                                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                    </svg>
                                    <select value={tabItems[selectedItem]} className="p-3 w-full bg-transparent appearance-none outline-none border rounded-lg shadow-sm focus:border-indigo-600"
                                        onChange={(e) => setSelectedItem(tabItems.indexOf(e.target.value))}
                                    >
                                        {
                                            tabItems.map((item, idx) => (
                                                <option key={idx} idx={idx}>
                                                    {item}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="inline-block">
                            <div class="grid grid-cols-7 gap-4">

                                <Link onClick={() => { setClickedValue(0) }}>
                                    <div>
                                        <div className={`h-18 rounded-md ${clickedvalue === 0 ? 'bg-purple-500' : 'bg-yellow-50'} shadow-md hover:shadow-lg`}>
                                            <div class="grid grid-cols-3 gap-0 p-4">
                                                <div class="col-span-2 ...">
                                                    <Text className='font-base text-xs text-center'>
                                                        Monday
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Link>

                                <Link onClick={() => { setClickedValue(1) }}>
                                    <div>
                                        <div className={`h-18 rounded-md ${clickedvalue === 1 ? 'bg-purple-500' : 'bg-yellow-50'} shadow-md hover:shadow-lg`}>
                                            <div class="grid grid-cols-3 gap-0 p-4">
                                                <div class="col-span-2">
                                                    <Text className='font-base text-xs text-center'>
                                                        Tuesday
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link onClick={() => { setClickedValue(2) }}>
                                    <div>
                                        <div className={`h-18 rounded-md ${clickedvalue === 2 ? 'bg-purple-500' : 'bg-yellow-50'} shadow-md hover:shadow-lg`}>
                                            <div class="grid grid-cols-3 gap-0 p-4">
                                                <div class="col-span-2">
                                                    <Text className='font-base text-xs text-center'>
                                                        Wednesday
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link onClick={() => { setClickedValue(3) }}>
                                    <div>
                                        <div className={`h-18 rounded-md ${clickedvalue === 3 ? 'bg-purple-500' : 'bg-yellow-50'} shadow-md hover:shadow-lg`}>
                                            <div class="grid grid-cols-3 gap-0 p-4">
                                                <div class="col-span-2">
                                                    <Text className='font-base text-xs text-center'>
                                                        Thursday
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link onClick={() => { setClickedValue(4) }}>
                                    <div>
                                        <div className={`h-18 rounded-md ${clickedvalue === 4 ? 'bg-purple-500' : 'bg-yellow-50'} shadow-md hover:shadow-lg`}>
                                            <div class="grid grid-cols-3 gap-0 p-4">
                                                <div class="col-span-2">
                                                    <Text className='font-base text-xs text-center'>
                                                        Friday
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link onClick={() => { setClickedValue(5) }}>
                                    <div>
                                        <div className={`h-18 rounded-md ${clickedvalue === 5 ? 'bg-purple-500' : 'bg-yellow-50'} shadow-md hover:shadow-lg`}>
                                            <div class="grid grid-cols-3 gap-0 p-4">
                                                <div class="col-span-2">
                                                    <Text className='font-base text-xs text-center'>
                                                        Saturday
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link onClick={() => { setClickedValue(6) }}>
                                    <div>
                                        <div className={`h-18 rounded-md ${clickedvalue === 6 ? 'bg-purple-500' : 'bg-yellow-50'} shadow-md hover:shadow-lg`}>
                                            <div class="grid grid-cols-3 gap-0 p-4">
                                                <div class="col-span-2">
                                                    <Text className='font-base text-xs text-center'>
                                                        Sunday
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </div>

                            <div className='mt-20'>
                                <Text className='text-center text-lg'>
                                    We are updating our Grid. 
                                </Text>

                                <Text className='text-center text-lg'>
                                    Our Season 2 schedule will be available in few days.
                                </Text>
                                {/* {renderTabContent()} */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mx-auto sm:px-6 lg:px-8 max-w-7xl pt-20'>
               
                <footer>
                    <div>
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-y-12 xl:gap-x-16 gap-x-8">
                            <div className="col-span-2 md:col-span-4 lg:col-span-2">
                                <a href="#" title="" className="flex">
                                    <img className="w-auto h-9" src={NrtvLogo} alt="" />
                                </a>
                                <p className="mt-6 text-base font-normal text-white">NRTV Zimbabwe. World class content beyond your imagination.</p>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">Platform</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1">Livestream </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> News </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1">Shop </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Request Content </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">Resources</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> About </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Features </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Pricing & Plans </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Contact </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">More</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> About Us </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Contact Us </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Contribute </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1">Support </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h6 className="text-xs font-semibold tracking-widest text-gray-300 uppercase">More</h6>

                                <ul className="mt-8 space-y-5">
                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Guides </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Terms & Conditions </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Privacy Policy </a>
                                    </li>

                                    <li>
                                        <a href="#" title="" className="flex text-base font-normal transition-all transform text-white hover:text-white duruation hover:translate-x-1"> Live Stream </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <hr className="mt-12 border-gray-400 lg:mt-20 xl:mt-24 sm:mt-16" />

                        <p className="mt-8 text-sm font-normal text-center text-white">© 2023, All Rights Reserved by NRTV</p>
                    </div>
                </footer>
                </div>
            </div>
        </div>
    )
}

export default TvGuide